/* ==========================================================================
 Team Section
   ========================================================================== */

.container {
	text-align: center;
}

.image_off,
.single-team:hover .image_on {
	display: none;
}
.image_on,
.single-team:hover .image_off {
	display: block;
}

.col-md-4 {
	padding-right: 0px;
}

.single-team {
	padding: 10px;
	position: relative;
	background: white;
	border-radius: 4px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	border-radius: 0px;
	overflow: hidden;
	margin: 0 50px;
}

.single-team .caption {
	cursor: pointer;
	position: absolute;
	opacity: 0;
	bottom: -300px;
	color: white;
	min-height: 50%;
	width: 100%;
	padding: 10px;
	-webkit-transition: 0.35s;
	transition: all 0.35s ease-in-out;
	background: rgba(0, 0, 0, 0.85);
}

.single-team .caption .heading {
	color: white;
}

.single-team:hover .caption {
	opacity: 1;
	transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
}

.single-team img {
	width: 100%;
	max-height: 713px;
}

.single-team .team-inner {
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.single-team .team-details {
	padding: 20px;
	width: 100%;
	position: relative;
	z-index: 10;
	text-align: center;
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	transition: 0.5s;
	background-color: white;
}

.single-team .team-details .team-title {
	margin-bottom: 5px;
	font-size: 15px;
	font-weight: 600;
	color: #263238;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.single-team .team-details p {
	margin-bottom: 15px;
	color: #00647c;
	letter-spacing: 1px;
}

.single-team img {
	cursor: pointer;
	-webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
	filter: grayscale(100%); /* FF 35+ */
	-webkit-transition: 0.35s;
	transition: all 0.35s ease-in-out;
}

.single-team img:hover {
	-webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
	filter: grayscale(0%); /* FF 35+ */
	-webkit-transition: 0.35s;
	transition: all 0.35s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1024px) {
	.single-team {
		margin: 0px 20px;
	}
}

@media (max-width: 767px) {
	.single-team {
		margin: 0px;
	}
}
