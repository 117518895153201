/* TOGGLER */
.navbar {
	display: block;
	padding: 0;
}

.navbarToggle {
	cursor: pointer;
	display: none;
	border: none;
	width: 50px;
	/* margin-right: 30px; */
}
.navbarToggle img {
	width: 100%;
}

.dropdown {
	display: none;
	width: 500px;
}

/* ==========================================================================
   Navbar Style
   ========================================================================== */
.logo-menu a {
	font-size: 20px;
	color: #fff;
}

.menu-bg {
	background-color: #fff;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	z-index: 999;
}

.menu-bg .menu-button:hover {
	cursor: pointer;
	color: #00647c;
}

.menu-bg .logo-menu a {
	color: #00647c;
}

.menu-bg .navbar-nav .nav-link {
	color: inherit;
	font-size: 14.5px;
	letter-spacing: 0.75px;
	cursor: pointer;
}

.menu-bg .navbar-nav .nav-link:hover {
	cursor: pointer;
	color: #00647c !important;
}

.menu-bg .navbar-nav .active {
	color: #00647c !important;
}

.menu-button:hover {
	cursor: pointer;
	opacity: #eee;
}

.bg-white {
	background-color: #f5f5f5 !important;
	z-index: 999;
	/* padding: 5px; */
}

.bg-white .menu-button {
	top: 0;
}

.bg-white .menu-button:hover {
	color: #00647c;
}

.bg-white .logo-menu a {
	color: #00647c;
}

.bg-white .navbar-nav .nav-link {
	color: #263238;
	font-size: 12px;
	letter-spacing: 0.75px;
}

.bg-white .navbar-nav .nav-link:hover {
	color: #00647c !important;
}

.bg-white .navbar-nav .active {
	color: #00647c !important;
}

.close-button {
	cursor: pointer;
}

.navbar.navbar-expand-md {
	padding: 0;
	margin: 0;
}

.navbar-expand-md .navbar-nav .nav-link {
	cursor: pointer;
	/* color: #fff; */
	font-size: 14.7px;
	letter-spacing: 0.75px;
	padding: 5px 0px;
	margin-left: 30px;
	position: relative;
}

.navbar-expand-md .navbar-nav .nav-link:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	background: #00647c;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	transition: 0.3s;
}

.navbar-expand-md .navbar-nav .active::before,
.navbar-expand-md .navbar-nav .current::before,
.navbar-expand-md .navbar-nav .nav-link:hover::before {
	width: 50%;
}

.navbar-brand {
	margin: 0 1rem;
	cursor: pointer;
}

.navbar-brand img {
	max-width: 138px;
}

.navbar-expand-md .navbar-nav .nav-link:focus,
.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link:hover:before {
	color: #00647c;
}

.navbar-expand-md .navbar-nav .active > .nav-link,
.navbar-expand-md .navbar-nav .nav-link.active,
.navbar-expand-md .navbar-nav .nav-link.active:before,
.navbar-expand-md .navbar-nav .nav-link.open,
.navbar-expand-md .navbar-nav .open > .nav-link {
	color: #00647c;
}

.navbar-expand-md .navbar-toggler {
	color: #00647c;
	border-radius: 0px;
	margin: 5px;
	cursor: pointer;
	font-size: 34px;
}

.mainmenu-area .navbar-nav .nav-link {
	color: #263238 !important;
}

nav .container {
	margin: 5px auto;
}

@media (max-width: 840px) {
	.navbar-brand {
		margin: 0 1rem;
		cursor: pointer;
	}

	.navbarToggle {
		display: block;
	}

	.collapse .navbar-nav {
		display: none !important;
	}

	/* DROPDOWN */
	.dropdown {
		display: block;
		overflow: hidden;
		height: 0px;
		width: 500px;
		transition: all 0.4s ease-in-out;
	}

	.dropdownOn {
		height: 200px;
		transition: all 0.4s cubic-bezier(0, 0.75, 0.85, 0.84);
	}
}

@media (max-width: 680px) {
	/* DROPDOWN */

	.navbar-nav {
		gap: 40px;
		margin: 12vh auto;
		justify-content: center;
		align-items: center;
		list-style: none;
	}

	.navbar-expand-md .navbar-nav .nav-link {
		text-align: center;
		color: #f5f5f5;
		font-size: 15px;
		letter-spacing: 0.75px;
		margin: 0 !important;
		width: 93%;
	}

	.dropdown-current {
		color: #00647c !important ;
		background-color: #f5f5f5;
	}

	.navbar-expand-md .navbar-nav .nav-link:before {
		display: none;
	}

	.dropdown {
		background-color: #6244e9;
		display: block;
		overflow: hidden;
		height: 0px;
		width: 100vw;
		transition: all 0.4s ease-in-out;
	}

	.dropdownOn {
		height: 100vh;
		transition: all 0.4s ease-in;
	}
}
