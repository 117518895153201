.container {
	max-width: 1140px;
	margin: 0 auto;
}

.whoWeAre .section {
	padding-bottom: 0px;
}

/* FEATURED */
.featured-box {
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
}

.featured-box .featured-icon img {
	font-size: 30px;
	display: block;
	margin-bottom: 20px;
	color: #00647c;
	width: 63px;
}

.featured-box .featured-content {
	position: relative;
}

.featured-content {
	text-align: left;
}

.featured-box .featured-content h4,
.service-subtitle {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 10px;
	transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.featured-box .featured-content p {
	font-size: 14px;
	color: #546e7a;
	line-height: 26px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 0;
}
