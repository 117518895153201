.featuredWorks {
	margin: 30px;
	height: fit-content;
	padding-top: 40px;
}

.swiperContent {
	margin: 40px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.text-left {
	max-width: 35%;
	padding-right: 60px;
}

.img-right {
	width: 60%;
}

.img-right img {
	width: 100%;
}

.lbl-project {
	font-size: 14px;
	font-weight: bold;
}

.lbl-client {
	font-weight: bold;
}

.project-description,
.project-name,
.client-title,
.lbl-client,
.lbl-project {
	font-size: 15.3;
	color: #546e7a;
	line-height: 26px;
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 0;
}

.project-description a {
	text-decoration: none;
	color: #00647c;
}

.platforms {
	display: flex;
	flex-direction: row;
	padding: 0 0 20px;
}

.platforms .platform {
	border: 1px solid #00647c;
	background: #00647c;
	color: #fff;
	margin: 3px;
	padding: 0 7px;
	border-radius: 20px;
	font-size: 12px;
	min-width: 60px;
	text-align: center;
}

/* CUSTOMIZE PAGINATION */
.swiper .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	position: relative;
	background-color: #00647c;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
	text-align: left;
	position: relative;
	top: 40px;
	margin-left: calc(100% - 85%);
}

.swiper-button-prev {
	width: 20px;
	height: 10px;
	clip-path: polygon(56% 50%, 100% 25%, 100% 77%);
	background-color: #00647c;
}

.swiper-button-next {
	clip-path: polygon(40% 48%, 0 21%, 0 74%);
	background-color: #00647c;
}

.swiper-button-next::after {
	clip-path: polygon(40% 48%, 0 21%, 0 74%);
}

.swiper-button-next :hover,
.swiper-button-prev :hover {
	background-color: rgba(183, 98, 223, 0.144) !important;
}

@media (max-width: 1190px) {
	.featuredWorks .section-title {
		margin-top: 60px;
	}

	.swiperContent {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.swiper .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background-color: #00647c;
	}

	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal {
		text-align: center;
		position: relative;
		top: 30px;
		margin-left: 0;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none !important;
	}

	.text-left {
		max-width: 100%;
		padding: 0px;
	}

	.img-right {
		width: 75%;
	}
}

@media (max-width: 680px) {
	.section-title {
		font-size: 20px !important;
	}

	.featuredWorks,
	.swiperContent {
		margin: 0px;
		padding: 10px;
	}

	.swiper .swiper-pagination-bullet {
		display: none;
	}
}
