.form-control {
	width: 100%;
	color: white;
	margin-bottom: 5px;
	padding: 15px 30px;
	font-size: 14px;
	border-top: none;
	border-right: none;
	border-bottom: 2px solid white;
	border-left: none;
	background: #4639bb;
	border-radius: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.form-control:focus {
	color: white;
	outline: none;
	box-shadow: none;
	background: #4639bb;
}

.form-control::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ccc;
	opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ccc;
}

.form-control::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #ccc;
}

.btn.disabled,
.btn:disabled {
	opacity: 1;
}

.help-block.with-errors > ul > li {
	font-weight: 500;
}

.contact-form {
	background-color: #4639bb;
	padding: 20px 0;
}

.contact-info {
	margin-bottom: 15px;
}

.contact-info h5 {
	margin-top: 5px;
	font-size: 18px;
	font-weight: 500;
}

.contact-info p {
	font-size: 14px;
	margin-bottom: 0;
}

.contact-container {
	padding: 0;
}

#contact {
	background: #ffffff;
	position: relative;
	overflow: hidden;
	padding-bottom: 2px;
}

#contact .contact-block {
	overflow: hidden;
}

.text-danger {
	font-size: 14px;
	margin-top: 10px;
}

.list-unstyled li {
	color: #d9534f;
}

#contactForm .form-control {
	color: white;
}

#contactForm .text-success {
	color: white !important;
	font-size: 18px;
}

#contactForm .form-control:focus {
	border-bottom: 0.5px solid white;
}

.form_error {
	color: #d9534f;
	font-size: 0.9rem;
	font-weight: 500;
}

.disableBtn {
	pointer-events: none;
	background-color: gray;
}

.formValidationErrorMessage {
	color: #d9534f;
}
