@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
	font-family: 'Open Sans', sans-serif;
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	background: #fff;
	overflow-x: hidden;
}

.App {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* --------------------------- */
/* Section Headers */
/* --------------------------- */
.section {
	padding: 80px 0px;
}

.section-subtitle {
	font-size: 17px;
	/* padding-top: 19px; */
	padding: 19px 60px 0 60px;
	line-height: 36px;
	color: #546e7a;
	max-width: 1000px;
	margin: 0 auto;
}

.section-header {
	color: #fff;
	margin-bottom: 40px;
	text-align: center;
	position: relative;
}

.section-header .section-title {
	font-size: 36px;
	margin-bottom: 20px;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	color: #263238;
	position: relative;
	letter-spacing: 3px;
}

.section-header .section-title:before {
	content: '';
	position: absolute;
	bottom: -8px;
	width: 80px;
	height: 2px;
	background: #00647c;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	transition: 0.3s;
}

.section-header span {
	font-size: 60px;
	color: rgba(0, 0, 0, 0.07);
	z-index: 2;
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	text-transform: capitalize;
	position: absolute;
	top: 7px;
	left: 0;
	width: 100%;
}

/* ==========================================================================
   2.2 Buttons
   ========================================================================== */
.btn {
	font-size: 14px;
	padding: 10px 30px;
	border-radius: 30px;
	font-weight: 400;
	color: #fff;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	display: inline-block;
}

.btn:focus,
.btn:active {
	box-shadow: none;
	outline: none;
	color: #fff;
}

.btn-common {
	background-color: transparent;
	position: relative;
	z-index: 1;
	padding: 12px 32px;
	border-radius: 30px;
	border: 2px white solid;
	border-radius: 0;
}

.btn-common:hover {
	color: #fff;
}

.btn-effect {
	overflow: hidden;
}

.btn-effect:after {
	content: '';
	position: absolute;
	width: 0;
	height: 100%;
	z-index: 1;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn-effect:hover:after {
	width: 100%;
}

.btn-border {
	border-style: solid;
	border-width: 2px;
	cursor: pointer;
	background-color: transparent;
	border-color: #fff;
	color: #fff;
}

.btn-border:hover {
	color: #ffffff;
	background-color: #00647c;
}

.btn-lg {
	padding: 14px 33px;
	text-transform: uppercase;
	font-size: 16px;
}

.btn-rm {
	padding: 7px 10px;
	text-transform: capitalize;
}

button:focus {
	outline: none !important;
}

.clear {
	clear: both;
}

.upArrow {
	width: 19px;
}

.scrolltotop {
	color: white;
	background-color: #6244e9;
	border: none;
	border-radius: 30px;
	position: fixed;
	bottom: 15px;
	right: 10px;
	width: 36px;
	height: 36px;
}

@media (max-width: 786px) {
	.section-header .section-title {
		font-size: 36px;
	}
}

@media (max-width: 1000px) {
	.section-header .section-title {
		font-size: 22px;
	}
}
