.callout-header {
	font-size: 115px;
	line-height: 110px;
	letter-spacing: 4px;
	font-weight: 700;
	margin-bottom: 5px;
}

.callout-subtext {
	font-size: 40px;
	line-height: 43px;
	font-family: 'Mukta', sans-serif;
	letter-spacing: 2px;
	padding-left: 10px;
}

.parallaxBanner {
	bottom: -15%;
}

.parallaxOverlayContent {
	display: flex;
	justify-content: flex-end;
}

.parallaxBannerText {
	justify-content: flex-end;
	height: 100vh;
	max-width: 630px;
	display: flex;
	flex-direction: column;
	font-weight: 700;
	padding: 0 30px;
}

#digital {
	max-width: 530px;
}

.strategy {
	justify-content: flex-start;
}

.solutions {
	padding-right: 100px;
}

.solutions p {
	margin-bottom: 40px;
}

@media (max-width: 855px) {
	.parallaxBannerText {
		max-width: 400px;
		display: flex;
		flex-direction: column;
		font-weight: 700;
	}

	.callout-header {
		font-size: 58px;
		line-height: 50px;
		margin-bottom: 30px;
	}

	.callout-subtext {
		font-size: 27px;
		letter-spacing: 4px;
		padding-left: 0px;
	}

	.digital {
		justify-content: flex-start;
	}

	.solutions {
		justify-content: flex-start;
		padding-right: 50px;
	}
}

@media (max-width: 260px) {
	.callout-header {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 30px;
	}

	.parallaxBannerText {
		max-width: 300px;
		display: flex;
		flex-direction: column;
		font-weight: 700;
	}
}
