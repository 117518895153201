.content {
	height: 45vh;
	color: white;
	text-align: center;
	margin-top: calc(50vh - 20vh);
}

.content h2 {
	color: white;
	font-size: 5vw;
	text-transform: uppercase;
	letter-spacing: 8px;
	font-weight: 300;
}

.parallaxVideo {
	object-fit: cover;
}

@media (max-width: 768px) {
	.content {
		margin-top: 40%;
	}
}
