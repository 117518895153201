/* ==========================================================================
   Footer Style
   ========================================================================== */
.block-title {
	font-size: 14px;
	font-weight: 600;
	color: white;
	margin-bottom: 30px;
	text-transform: uppercase;
	position: relative;
}

footer .footer-Content {
	background-color: #4639bb;
	padding: 60px 0;
	color: white;
	letter-spacing: 2px;
	font-weight: 300;
	font-size: 12px;
}

.widget {
	width: fit-content;
	text-align: left;
}

.textwidget {
	font-size: 12px;
	line-height: 24px;
	margin-bottom: 10px;
	color: white;
}

.textwidget p {
	color: white;
	font-size: 12px;
}

footer .menu {
	padding-left: 0;
}

footer .menu li {
	padding-bottom: 7px;
}

footer .menu li a {
	color: white;
}

footer .menu li a:hover {
	color: white;
}

.contact-footer {
	margin: 0;
	padding: 0;
	display: inline-block;
	list-style: none;
	text-align: left;
}

.contact-footer li {
	padding: 3px 0;
	margin-bottom: 15px;
}

.contact-footer li strong {
	float: left;
}

.contact-footer li span {
	display: block;
}

.contact-footer li span a {
	color: white;
}

.contact-footer li span a:hover {
	color: white;
}

.footer-social li .facebook:hover {
	background-color: #3b5998;
}

.footer-social li .twitter:hover {
	background-color: #55acee;
}

.footer-social li .linkedin:hover {
	background-color: #007bb5;
}

.footer-social li .google-plus:hover {
	background-color: #dd4b39;
}

#copyright {
	background-color: #ffffff;
	padding: 20px 0 10px;
	border-top: 1px solid #eaeaea;
	color: #546e7a;
}

#copyright p {
	margin-bottom: 0;
	line-height: 40px;
}

#copyright p a {
	color: #546e7a;
}

#copyright p a:hover {
	color: #00647c;
}

#copyright .nav-inline .nav-link {
	color: #546e7a;
	padding: 10px 0;
	margin-left: 10px;
}

#copyright .nav-inline .nav-link:hover {
	color: #00647c;
}

@media (max-width: 767px) {
	.block-title {
		text-align: left;
	}

	.footer-content {
		text-align: left;
	}

	.footerLogo {
		text-align: left;
		width: 419.33px;
		margin: 0 auto;
		padding-left: 30px;
	}

	.widget {
		width: fit-content;
		margin: 0 auto;
		padding-left: 20px;
	}

	.widget ul {
		text-align: left;
	}

	.project-title,
	.client-area {
		display: flex;
		font-size: 18px;
	}

	.lbl-project {
		padding-right: 15px;
		font-weight: bold;
	}

	.lbl-client {
		padding-right: 20px;
		font-weight: bold;
	}

	.slider-img {
		min-height: 630px;
	}

	.brams-slide-img {
		background: url(../img/featured_works/bridgerock_m.jpg) no-repeat center center / contain;
	}

	.rr-slide-img {
		background: url(../img/featured_works/rapid_m.jpg) no-repeat center center / contain;
	}

	.rindle-slide-img {
		background: url(../img/featured_works/rindle_m.jpg) no-repeat center center / contain;
	}

	.greensheet-slide-img {
		background: url(../img/featured_works/green_m.jpg) no-repeat center center / contain;
	}

	footer .contact-title {
		padding-top: 35px;
	}
}
