#whatwedo .section-title-ctr {
	padding: 80px 0 0 0;
}

.row {
	margin-right: 0 !important;
}

.service-detail-title {
	padding-bottom: 30px;
}

.service-detail {
	font-family: 'Open Sans', sans-serif;
	border: none;
	margin: 0;
	padding: 90px 5px 114px;
}

#web-development {
	background-color: #eaeeff;
}

#mobile-apps {
	background-color: #cedbff;
}

#digital-marketing {
	background-color: #d8e9ff;
	margin-bottom: 80px;
}

.service-detail h3 {
	color: #00647c;
	font-size: 15px;
	margin: 36px 0;
	letter-spacing: 2px;
	font-weight: 700;
}

.service-detail h2 {
	font-size: 72px;
	font-weight: 700;
}

.service-detail p {
	color: #546e7a;
	font-size: 15.3px;
}

.service-detail ul li {
	margin-bottom: 14px;
	color: #546e7a;
}

.service-detail ul {
	list-style: none;
	padding: 0px;
}

.service-subtitle {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 10px;
	letter-spacing: 1px;
	color: #546e7a;
	line-height: 26px;
}

.service-detail ul li::before {
	content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
	color: #00647c; /* Change the color */
	display: inline-block; /* Needed to add space between the bullet and the text */
	width: 1em; /* Also needed for space (tweak if needed) */
	margin-left: -1em;
}

.service-right {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 20px;
}
.service-left {
	margin-left: 75px;
}

.service-content {
	background: rgba(53, 55, 62, 0.75);
	border: 6px solid rgba(255, 255, 255, 0.55);
	padding: 60px 80px;
	color: #fff;
	text-align: center;
	position: absolute;
	top: 20%;
	left: 0px;
	right: 0px;
	width: 70%;
	margin: 0 auto;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.service-icon {
	color: white;
	width: 71px;
}

.item-boxes {
	padding: 30px;
	border-radius: 4px;
	margin: 15px 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.item-boxes .icon {
	margin-bottom: 10px;
}

.item-boxes .icon i {
	font-size: 30px;
	line-height: 60px;
}

.item-boxes h4 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
}

.item-boxes p {
	margin: 0;
}

.item-boxes:hover,
.item-boxes.selected {
	background: transparent;
	border: black solid 1px;
}

#services {
	padding: 0px;
}

.service-icon {
	font-size: 38px;
}

.services-item {
	padding: 0;
	text-align: center;
	position: relative;
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	text-align: left;
	background-color: black;
	cursor: pointer;
}

.service-border {
	position: absolute;
	z-index: 2;
	outline: 2px solid white;
	outline-offset: -22px;
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.service-detail {
		text-align: center;
	}

	.service-detail ul {
		text-align: left;
	}

	.service-detail h2 {
		font-size: 36px;
	}
}
@media (min-width: 991px) and (max-width: 1166px) {
	#web-development h2 {
		font-size: 60px;
	}
}

@media (max-width: 320px) {
	.service-detail h2 {
		font-size: 31px;
	}
}
